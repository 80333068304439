import React from "react";
import "bootswatch/dist/pulse/bootstrap.min.css";
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route, Link, NavLink,
} from "react-router-dom";
import PageNotFound from "./PageNotFound";
import Formula1 from './Formula1';
import Home from "./Home";
import Formula2 from "./Formula2";

function App() {
    document.title = "Singleseaters.net";
    return (
        <div>
            <BrowserRouter>
                <header>
                    <nav className="navbar navbar-expand-md navbar-light bg-danger pt-2 pb-2 m-0">
                        <div className="container-xl">
                            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbar-menu" aria-expanded="false">
                                <span className="navbar-toggler-icon"/>
                            </button>
                            <NavLink
                                to="/"
                                className={({isActive}) =>
                                    (isActive ? "navbar-brand text-white fw-bold" : "navbar-brand text-white fw-normal")}
                            >
                                Singleseaters.net
                            </NavLink>
                            <div className="navbar-nav flex-row order-md-last">
                                <button type="button" id="themeModeButton" name="themeModeButton" className="btn btn-sm btn-icon text-white shadow-none"/>
                            </div>
                        </div>
                    </nav>
                </header>
                <div id="navbar-menu" className="navbar-expand-md">
                    <nav className="navbar navbar-collapse collapsed navbar-dark bg-primary p-0 rounded-bottom">
                        <div className="container-xl">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink
                                            to="/f1-calendar"
                                            className={({isActive}) =>
                                                (isActive ? "nav-link active" : "nav-link")}
                                        >
                                            F1 Calendar
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/f2-calendar"
                                            className={({isActive}) =>
                                                (isActive ? "nav-link active" : "nav-link")}
                                        >
                                            F2 Calendar
                                        </NavLink>
                                    </li>
                                </ul>
                        </div>
                    </nav>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <Routes>
                            <Route exact path="/" element={<Home/>}/>
                            <Route exact path="/f1-calendar" element={<Formula1/>}/>
                            <Route exact path="/f2-calendar" element={<Formula2/>}/>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>
                    </div>
                </div>
            </BrowserRouter>
        </div>
);
}

export default App;
